<template>
  <div>
    <!-- 联系我们 -->
    <div class="banner" id="investor_banner1" :style="{backgroundImage:('url('+banner+')')}">
      <!-- <img
        :src="banner"
      /> -->
      <span>{{$t('indexnav.Follow us')}}<i :style="{
            display: this.$store.state.nowlanguage === 'en' ? 'none' : '',
          }">Follow us</i></span>
    </div>

    <section class="ab-item1">
      <!-- <a href="/">{{$t('indexnav.Home')}}</a> > <a href="/contact/default.html">{{$t('indexnav.Contact Us')}}</a> >
      {{$t('indexnav.Follow us')}} -->
      <bread-crumb />
    </section>

    <figure>
      <div>
        <div>
          <img :src="$t('Contact US.img')" />
        </div>
        <div>
          <img :src="qr_codeImg" style="max-height: 240px;"/>
        </div>
      </div>
    </figure>
  </div>
</template>

<script>
import { TouZiZheChaXun } from "@/api/investors";
import { PageType } from '@/utils/constant';
import {getAction} from '@/api/manage'

export default {
  data () {
    return {
     qr_codeImg:'',
      banner: ''
    }
  },
  created () {
    // 初始化banner
    let that = this
    getAction('/jeecg-system/common/frontend/advertise/active_list', { typeCode: PageType.GUANZHUWOMEN }).then(res => {
      if (res.result.records instanceof Array && res.result.records.length > 0) {
         if (res.result.records[0].isMultipatLanguge!=='0') {
          if (this.$store.state.nowlanguage == "zh")
            that.banner = res.result.records[0].sampleImg;
          if (this.$store.state.nowlanguage == "tr")
            that.banner = res.result.records[0].tranditionalImg
          if (this.$store.state.nowlanguage == "en")
            that.banner = res.result.records[0].enImg;
        }else{
        that.banner = res.result.records[0].sampleImg
        }
      }
    });

    TouZiZheChaXun().then(res=>{
      console.log(res);
      let qr_code={}
        qr_code = res.result.filter((item) => {
        return item.key.includes("qr_code");
      });
      this.qr_codeImg=qr_code[0].value
    })
  }
};
</script>

<style scoped>
header + a img {
  max-width: 100%;
  width: 100%;
}

.banner {
  position: relative;
}
.banner img {
  width: 100%;
  max-width: 100vw;
  display: block;
}

section,
figure {
  margin: 1.5em auto 0 auto;
  width: 100%;
  max-width: 1200px;
}

.ab-item1 {
  padding: 0 0 1.5em 0;
  border-bottom: 1px solid #d2d2d2;
  font-size: 1.5rem;
  color: #595757;
}

figure div {
  display: flex;
  background: #eee;
}

figure div div {
  width: 50%;
  display: flex;
  justify-items: center;
  justify-content: center;
  padding: 2em 0;
}

figure div div img {
  width: 40%;
}

@media screen and (max-width: 1200px) {
  .ab-item1 {
    font-size: 1.2em;
    padding: 0 1.5em 1.5em 1.5em;
  }

  figure {
    padding: 0 1.5em 1.5em 1.5em;
  }
}

@media screen and (max-width: 768px) {
  figure div {
    flex-direction: column;
  }

  figure div div {
    width: 100%;
  }

  figure div div img {
    margin: 0 auto;
    width: 45%;
  }
}
</style>
